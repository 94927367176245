import React, {createContext, useReducer} from 'react';

const SurveyContext = createContext();

const surveyReducer = (state, action) => {
    switch (action.type) {
        case 'ADD':
            if (Array.isArray(action.payload)) {
                const newData = action.payload.filter(newSurvey =>
                    !state.surveys.some(survey => survey.id === newSurvey.id)
                );

                return {
                    ...state,
                    surveys: [...state.surveys, ...newData]
                };
            }

            return { state }
        case 'SHOW':
            return { ...state, survey: action.payload };
        default:
            return state;
    }
};

export const SurveyProvider = ({ children }) => {
    const [surveyState, surveyDispatch] = useReducer(surveyReducer, { survey: {}, surveys: [] });

    return (
        <SurveyContext.Provider value={{ surveyState, surveyDispatch }}>
            {children}
        </SurveyContext.Provider>
    );
};

export default SurveyContext;

