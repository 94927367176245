import React, { useState, useEffect } from 'react';
import {PlusCircleIcon, StarIcon} from "@heroicons/react/24/outline";
import {CheckCircleIcon} from "@heroicons/react/16/solid";
import {PacmanLoader} from "react-spinners";
import FetchSurvey from "../../../components/fetch/FetchSurvey";
import SubmitSurveyForm from "../../../components/fetch/SubmitSurveyForm";

const Form = ({ surveyID }) => {
    const [survey, setSurvey] = useState(null);
    const [answers, setAnswers] = useState({});
    const [isLoading, setIsLoading] = useState(true)
    const [error, setError] = useState(null)
    const [errors, setErrors] = useState({})
    const [submitSuccess, setSubmitSuccess] = useState(false);

    useEffect(() => {
        const fetchSurvey = async (surveyID) => {
            try {
                (await FetchSurvey)(surveyID).then((response) => {
                    setSurvey(response);
                })
            } catch (error) {
                setError(error.message)
            } finally {
                setIsLoading(false)
            }
        };

        fetchSurvey(surveyID);
    }, [surveyID]);

    const handleChange = (questionId, value) => {
        setAnswers({
            ...answers,
            [questionId]: value,
        });

        if (errors[questionId]) {
            setErrors({
                ...errors,
                [questionId]: !value.trim(),
            });
        }
    };

    const validateForm = () => {
        const newErrors = {};
        survey.questions.forEach((question) => {
            if (!answers[question.id] || !answers[question.id].trim()) {
                newErrors[question.id] = 'This field is required';
            }
        });
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!validateForm()) {
            return;
        }

        const feedback = {
            answers: Object.keys(answers).map((questionId) => {
                return {
                    question_id: questionId,
                    answer: answers[questionId],
                };
            }),
        };

        const submitSurvey = async (surveyID, body) => {
            try {
                (await SubmitSurveyForm)(surveyID, body).then((data) => {
                    console.log(data)
                    setSubmitSuccess(true);
                })
            } catch (error) {
                setError(error.message)
            } finally {
                setIsLoading(false)
            }
        };

        submitSurvey(survey.id, feedback)
    };

    const override = {
        display: "block",
        margin: "0",
        borderColor: "#8b2dff",
    };

    if (!survey) {
        return (
            <div className="py-10 text-left m-0">
                <h2 className="text-light-purple/80 text-xs pb-6">Loading survey...</h2>
                <PacmanLoader
                    color="#8b2dff"
                    loading={true}
                    cssOverride={override}
                    size={24}
                    aria-label="Loading Survey"
                    data-testid="loader"
                />
            </div>
        )
    }

    if (submitSuccess) {
        return (
            <div className="py-10">
                <div className="sm:flex sm:items-start">
                    <div
                        className="flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-green-100 mx-0 sm:h-10 sm:w-10">
                        <CheckCircleIcon aria-hidden="true" className="h-6 w-6 text-green-600"/>
                    </div>
                    <div className="mt-2 text-left sm:ml-4">
                        <h3 className="text-base font-semibold leading-6 text-light-purple">
                            {survey.title}
                        </h3>
                        <div className="mt-2">
                            <p className="text-sm text-light-purple/80">
                                Thank you for submitting the feedback form. We will evaluate your responses and make the necessary improvements accordingly.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <form onSubmit={handleSubmit} className="py-10">
            <h2 className="text-light-purple text-2xl">{survey.title}</h2>
            <p className="block text-sm font-medium leading-6 text-light-purple/80 pb-6">{survey.description}</p>

            {survey.questions.map((question) => (
                <div key={question.id} className="py-6">
                    <label className="block text-md font-medium leading-6 text-light-purple/80">{question.text}</label>
                    {question.description && (
                        <p className="block text-sm font-medium leading-6 text-light-purple/60">{question.description}</p>
                    )}
                    {question.type === 'stars' && (
                        <div className="flex mt-2">
                            {[1, 2, 3, 4, 5].map((star) => (
                                <StarIcon
                                    key={star}
                                    onClick={() => handleChange(question.id, star.toString())}
                                    className={`w-8 cursor-pointer ${star <= (answers[question.id] || 0) ? 'text-yellow-500' : 'text-gray-400'}`}
                                />
                            ))}
                        </div>
                    )}
                    {question.type === 'text' && (
                        <textarea
                            onChange={(e) => handleChange(question.id, e.target.value)}
                            rows={4}
                            className="mt-2 block w-full rounded-md border-0 py-1.5 text-medium-aubergine shadow-sm ring-1 ring-inset ring-violet-300 placeholder:text-medium-aubergine/60 focus:ring-2 focus:ring-inset focus:ring-violet-600 sm:text-sm sm:leading-6"
                            defaultValue={''}
                        />
                    )}
                    {question.type === 'checkbox' && (
                        <div className="mt-2">
                            {question.options.map((option, index) => {
                                return (
                                    <div key={option} className="relative flex items-start py-1">
                                        <div className="flex h-6 items-center">
                                            <input
                                                id={`${question.id}-${index}`}
                                                type="checkbox"
                                                className="h-4 w-4 rounded border-violet-300 text-violet-600 focus:ring-violet-600"
                                                value={option}
                                                onChange={(e) => {
                                                    const value = answers[question.id] || '';
                                                    const values = value.split(' | ').filter(Boolean);
                                                    if (e.target.checked) {
                                                        if (!values.includes(option)) {
                                                            values.push(option);
                                                        }
                                                    } else {
                                                        const index = values.indexOf(option);
                                                        if (index !== -1) values.splice(index, 1);
                                                    }
                                                    handleChange(question.id, values.join(' | '));
                                                }}
                                            />
                                        </div>
                                        <div className="ml-3 text-sm leading-6">
                                            <label for={`${question.id}-${index}`} className="font-medium text-light-purple/80">
                                                {option}
                                            </label>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    )}
                    {errors[question.id] && <div className="text-red-300 mt-2 text-sm">{errors[question.id]}</div>}
                </div>
            ))}
            <button
                type="submit"
                className="m-0 inline-flex items-center gap-x-1.5 rounded-md bg-violet-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-violet-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-violet-600"
            >
                <PlusCircleIcon aria-hidden="true" className="-ml-0.5 h-5 w-5"/>
                Submit Feedback
            </button>
            {submitSuccess && <div style={{ color: 'green' }}>Feedback submitted successfully!</div>}
        </form>
    );
};

export default Form;