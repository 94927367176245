import React, {useEffect, useState} from 'react';
import {Heading, Subheading} from "../../../components/catalyst/heading";
import {Badge} from "../../../components/catalyst/badge";
import {BanknotesIcon, InformationCircleIcon} from "@heroicons/react/16/solid";
import {Divider} from "../../../components/catalyst/divider";
import {DescriptionDetails, DescriptionList, DescriptionTerm} from "../../../components/catalyst/description-list";

const FeedbackList = ({ surveys, feedback }) => {
    const [survey, setSurvey] = useState({});
    const [questionsMap, setQuestionsMap] = useState({});

    useEffect(() => {
        if (feedback) {
            const fetchSurveyQuestions = async () => {
                const survey = surveys.find(s => s.id === feedback.survey_id);

                if (survey) {
                    setSurvey(survey)

                    const questionsMap = survey.questions.reduce((acc, question) => {
                        acc[question.id] = question.text;
                        return acc;
                    }, {});

                    setQuestionsMap(questionsMap);
                }

                console.log("Unable to find survey with id", feedback.survey_id);
            };

            fetchSurveyQuestions();
        }
    }, [feedback]);

    if ((!feedback || Object.keys(feedback).length === 0) || Object.keys(feedback).length === 0) {
        return <div>Please select a feedback</div>;
    }

    return (
        <div className="mx-auto max-w-6xl px-12">
            <div className="pt-12">
                <div className="flex items-center gap-4">
                    <Heading>{survey.title}</Heading>
                    <Badge color="blue">{feedback.survey_id}</Badge>
                </div>
                <div className="isolate mt-2.5 flex flex-wrap justify-between gap-x-6 gap-y-4">
                    <div className="flex flex-wrap gap-x-10 gap-y-4 py-1.5">
                        <span className="flex items-center gap-3 text-base/6 text-zinc-950 sm:text-sm/6 dark:text-white">
                          <InformationCircleIcon className="size-4 shrink-0 fill-zinc-400 dark:fill-zinc-500"/>
                          <span>{feedback.id}</span>
                        </span>
                    </div>
                </div>
            </div>
            <div className="pt-12">
                <Subheading>Feedback</Subheading>
                <Divider className="mt-4"/>
                <DescriptionList>
                    {feedback && feedback.answers.map((answer, idx) => (
                        <>
                            <DescriptionTerm>{questionsMap[answer.question_id] || 'Unknown question'}</DescriptionTerm>
                            <DescriptionDetails>{answer.answer}</DescriptionDetails>
                        </>
                    ))}
                </DescriptionList>
            </div>
        </div>
    );
};

export default FeedbackList;