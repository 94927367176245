import React, {createContext, useReducer} from 'react';

const FeedbackContext = createContext();

const feedbackReducer = (state, action) => {
    switch (action.type) {
        case 'ADD':
            if (Array.isArray(action.payload)) {
                const feedbacksBySurvey = action.payload.reduce((acc, feedback) => {
                    const { survey_id } = feedback;
                    if (!acc[survey_id]) {
                        acc[survey_id] = [];
                    }
                    acc[survey_id].push(feedback);
                    return acc;
                }, {});

                return {
                    ...state,
                    feedbacks: {
                        ...state.feedbacks,
                        ...feedbacksBySurvey,
                    },
                };
            }

            return { ...state }
        case 'SHOW':
            return { ...state, feedback: action.payload };
        default:
            return state;
    }
};

export const FeedbackProvider = ({ children }) => {
    const [feedbackState, feedbackDispatch] = useReducer(feedbackReducer, { feedback: {}, feedbacks: {} });

    return (
        <FeedbackContext.Provider value={{ feedbackState, feedbackDispatch }}>
            {children}
        </FeedbackContext.Provider>
    );
};

export default FeedbackContext;

