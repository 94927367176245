import React, {useContext, useState} from 'react';
import {XMarkIcon} from "@heroicons/react/24/outline";
import {Button} from "../../../components/catalyst/button";
import CreateSurveyForm from "../../../components/fetch/CreateSurveyForm";
import FeedbackContext from "../../../storage/feedbackContext";
import Feedback from "./Feedback";
import SurveyContext from "../../../storage/surveyContext";

const CreateSurvey = () => {
    const { surveyState } = useContext(SurveyContext)
    const { survey, surveys } = surveyState
    const { feedbackState } = useContext(FeedbackContext);
    const { feedback } = feedbackState;
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [questions, setQuestions] = useState([{ text: '', description: '', type: 'text', options: [] }]);

    const handleTitleChange = (e) => {
        setTitle(e.target.value);
    };

    const handleDescriptionChange = (e) => {
        setDescription(e.target.value);
    };

    const handleQuestionChange = (index, e) => {
        const { name, value } = e.target;
        setQuestions((prevQuestions) => {
            const newQuestions = [...prevQuestions];
            newQuestions[index] = { ...newQuestions[index], [name]: value };
            return newQuestions;
        });
    };

    const handleOptionChange = (index, optionIndex, e) => {
        const { value } = e.target;
        setQuestions((prevQuestions) => {
            const newQuestions = [...prevQuestions];
            newQuestions[index].options[optionIndex] = value;
            return newQuestions;
        });
    };

    const addQuestion = () => {
        setQuestions((prevQuestions) => [...prevQuestions, { text: '', description: '', type: 'text', options: [] }]);
    };

    const addOption = (index) => {
        setQuestions((prevQuestions) => {
            const newQuestions = [...prevQuestions];
            newQuestions[index].options = [...newQuestions[index].options, ''];
            return newQuestions;
        });
    };

    const removeQuestion = (index) => {
        setQuestions((prevQuestions) => prevQuestions.filter((_, qIndex) => qIndex !== index));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const survey = {
            title,
            description,
            questions,
        };

        const submitSurvey = async (body) => {
            try {
                (await CreateSurveyForm)(body).then((data) => {})
            } catch (error) {
                setError(error.message)
            } finally {
                setIsLoading(false)
            }
        };

        submitSurvey(survey)
    };

    if (survey && Object.keys(survey).length > 0) {
        return (
            <>

            </>
        )
    }

    if (feedback && Object.keys(feedback).length > 0) {
        return (
            <>
                <Feedback feedback={feedback} surveys={surveys} />
            </>
        )
    }

    return (
        <form onSubmit={handleSubmit} className="p-5">
            <h1 className="text-base font-semibold leading-7 text-stone-700">Create Survey</h1>
            <p className="mt-1 text-sm leading-6 text-stone-600">Lorem ipsum dolar sit.</p>

            <div className="mt-10">
                <legend className="text-sm font-semibold leading-6 text-stone-700 mt-10 mb-4">Survey title</legend>
                <input
                    type="text"
                    value={title}
                    placeholder={"Title"}
                    onChange={handleTitleChange}
                    required
                    className="block w-[52rem] rounded-md border-0 py-1.5 text-stone-700 shadow-sm ring-1 ring-inset ring-stone-300 placeholder:text-stone-400 focus:ring-2 focus:ring-inset focus:ring-violet-600 sm:text-sm sm:leading-6"
                />

                <input
                    type="text"
                    value={description}
                    placeholder={"Description"}
                    onChange={handleDescriptionChange}
                    required
                    className="block mt-4 w-[52rem] rounded-md border-0 py-1.5 text-stone-700 shadow-sm ring-1 ring-inset ring-stone-300 placeholder:text-stone-400 focus:ring-2 focus:ring-inset focus:ring-violet-600 sm:text-sm sm:leading-6"
                />
            </div>

            <legend className="text-sm font-semibold leading-6 text-stone-700 mt-10 mb-4">Questions</legend>

            {questions.map((question, index) => (
                <div key={index} className="w-full">
                    <div className="flex gap-x-4 mb-3">
                        <select
                            name="type"
                            value={question.type}
                            onChange={(e) => handleQuestionChange(index, e)}
                            required
                            className="inline w-40 rounded-md border-0 py-1.5 text-stone-700 shadow-sm ring-1 ring-inset ring-stone-300 focus:ring-2 focus:ring-inset focus:ring-violet-600 sm:max-w-xs sm:text-sm sm:leading-6"
                        >
                            <option value="text">Text</option>
                            <option value="stars">Stars</option>
                            <option value="checkbox">Checkbox</option>
                        </select>
                        <input
                            name="description"
                            type="text"
                            value={question.description}
                            placeholder={"Description"}
                            onChange={(e) => handleQuestionChange(index, e)}
                            className="block w-80 rounded-md border-0 py-1.5 text-stone-700 shadow-sm ring-1 ring-inset ring-stone-300 placeholder:text-stone-400 focus:ring-2 focus:ring-inset focus:ring-violet-600 sm:text-sm sm:leading-6"
                        />
                        <input
                            type="text"
                            name="text"
                            value={question.text}
                            placeholder={"Question"}
                            onChange={(e) => handleQuestionChange(index, e)}
                            required
                            className="inline w-80 rounded-md border-0 py-1.5 text-stone-700 shadow-sm ring-1 ring-inset ring-stone-300 placeholder:text-stone-400 focus:ring-2 focus:ring-inset focus:ring-violet-600 sm:text-sm sm:leading-6"
                        />
                        <button type="button" onClick={() => removeQuestion(index)}>
                            <XMarkIcon className="h-5 w-5 text-stone-600"/>
                        </button>
                    </div>

                    {question.type === 'checkbox' && (
                        <div>
                            <h3>Options</h3>
                            {question.options.map((option, optionIndex) => (
                                <div key={optionIndex} className="py-1">
                                    <input
                                        type="text"
                                        value={option}
                                        onChange={(e) => handleOptionChange(index, optionIndex, e)}
                                        required
                                        className="inline rounded-md border-0 py-1.5 text-stone-700 shadow-sm ring-1 ring-inset ring-stone-300 placeholder:text-stone-400 focus:ring-2 focus:ring-inset focus:ring-violet-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            ))}
                            <button type="button" onClick={() => addOption(index)}>
                                Add Option
                            </button>
                        </div>
                    )}
                </div>
            ))}

            <div className="">
                <Button onClick={addQuestion}>Add another question</Button>
            </div>

            <div className="mt-10">
                <button
                    type="submit"
                    className="rounded-md bg-violet-600 disabled:bg-stone-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-violet-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-violet-600"
                >
                    Create Survey
                </button>
            </div>
        </form>
    );
};

export default CreateSurvey;
