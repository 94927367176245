import React, {useContext, useEffect, useState} from 'react';
import UpdateSurveyForm from "../../../../components/fetch/UpdateSurveyForm";
import {Heading, Subheading} from "../../../../components/catalyst/heading";
import {CheckIcon, ChevronUpDownIcon, PlusIcon} from "@heroicons/react/16/solid";
import surveyContext from "../../../../storage/surveyContext";
import {useParams} from "react-router-dom";
import {Textarea} from "../../../../components/catalyst/textarea";
import {Divider} from "../../../../components/catalyst/divider";
import {Input} from "../../../../components/catalyst/input";
import {Text} from "../../../../components/catalyst/text";
import {Button} from "../../../../components/catalyst/button";
import {Badge} from "../../../../components/catalyst/badge";
import {Listbox, ListboxButton, ListboxOption, ListboxOptions} from "@headlessui/react";

const UpdateSurvey = () => {
    const { surveyID } = useParams();
    const { surveyState } = useContext(surveyContext)
    const { survey, surveys } = surveyState;
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [updatedSurvey, setUpdatedSurvey] = useState({});
    const types = ['text', 'stars', 'checkbox'];

    useEffect(() => {
        if (Object.keys(updatedSurvey).length > 0) {
            return
        }

        if (survey && Object.keys(survey).length > 0) {
            setUpdatedSurvey(survey)
        } else {
            const foundSurvey = surveys.find(s => s.id === surveyID)
            if (foundSurvey) {
                setUpdatedSurvey(foundSurvey)
            }
        }
    }, [survey, surveys]);

    const handleTitleChange = (e) => {
        setUpdatedSurvey({ ...updatedSurvey, title: e.target.value });
    };

    const handleDescriptionChange = (e) => {
        setUpdatedSurvey({ ...updatedSurvey, description: e.target.value });
    };

    const handleQuestionChange = (index, e) => {
        const { name, value } = e.target;
        const newQuestions = [...updatedSurvey.questions];
        newQuestions[index] = { ...newQuestions[index], [name]: value };
        setUpdatedSurvey({ ...updatedSurvey, questions: newQuestions });
    };

    const handleTypeChange = (index, value) => {
        const newQuestions = [...updatedSurvey.questions];
        if (value === "checkbox") {
            newQuestions[index] = { ...newQuestions[index], ["type"]: value, ["options"]: [] };
        } else {
            newQuestions[index] = { ...newQuestions[index], ["type"]: value };
        }
        setUpdatedSurvey({ ...updatedSurvey, questions: newQuestions });
    };

    const handleOptionChange = (index, optionIndex, e) => {
        const { value } = e.target;
        const newQuestions = [...updatedSurvey.questions];
        newQuestions[index].options[optionIndex] = value;
        setUpdatedSurvey({ ...updatedSurvey, questions: newQuestions });
    };

    const addQuestion = () => {
        setUpdatedSurvey({ ...updatedSurvey, questions: [...updatedSurvey.questions, { text: '', description: '', type: 'text', options: [] }] });
    };

    const addOption = (index) => {
        const newQuestions = [...updatedSurvey.questions];
        newQuestions[index].options.push('');
        setUpdatedSurvey({ ...updatedSurvey, questions: newQuestions });
    };

    const removeQuestion = (index) => {
        const newQuestions = survey.questions.filter((_, qIndex) => qIndex !== index);
        setUpdatedSurvey({ ...updatedSurvey, questions: newQuestions });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            (await UpdateSurveyForm)(updatedSurvey.id, updatedSurvey).then((data) => {})
        } catch (error) {
            setError(error.message)
        } finally {
            setIsLoading(false)
        }
    };

    if (!Object.keys(updatedSurvey).length > 0) {
        return (
            <p>Unable to find survey</p>
        )
    }

    return (
        <div className="w-full h-full overflow-y-scroll px-12">
            <div className="mx-auto max-w-6xl">
                <div className="pt-12">
                    <form method="post" className="mx-auto max-w-4xl" onSubmit={handleSubmit}>
                        <div className="flex items-center gap-4">
                            <Heading>Update Survey</Heading>
                            <Badge color="blue">
                                <a href={`/surveys/${updatedSurvey.id}`}>
                                    {updatedSurvey.id}
                                </a>
                            </Badge>
                        </div>

                        <Divider className="my-10 mt-6"/>

                        <section className="grid gap-x-8 gap-y-6 sm:grid-cols-2">
                        <div className="space-y-1">
                                <Subheading>Survey title</Subheading>
                            </div>
                            <div>
                                <Input name="title" type="text" value={updatedSurvey.title} onChange={handleTitleChange}
                                       required/>
                            </div>
                        </section>

                        <Divider className="my-10" soft/>

                        <section className="grid gap-x-8 gap-y-6 sm:grid-cols-2">
                            <div className="space-y-1">
                                <Subheading>Survey description</Subheading>
                            </div>
                            <div>
                                <Textarea name="description" value={updatedSurvey.description} onChange={handleDescriptionChange}/>
                            </div>
                        </section>

                        {updatedSurvey.questions.map((question, index) => (
                            <>
                                <Divider className="my-10" soft/>

                                <section className="grid gap-x-8 gap-y-6 sm:grid-cols-2">
                                    <div className="space-y-1">
                                        <Subheading>Question #{index + 1}</Subheading>

                                        <Button outline onClick={() => removeQuestion(index)}>Remove question</Button>
                                    </div>

                                    <div className="grid grid-cols-2 gap-6">
                                        <Listbox className="col-span-2" name="type" value={question.type}
                                                 onChange={(e) => handleTypeChange(index, e)}
                                        >
                                            <div className="relative mt-2">
                                                <ListboxButton className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                                    <span className="block truncate">{question.type}</span>
                                                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronUpDownIcon aria-hidden="true" className="h-5 w-5 text-gray-400" />
          </span>
                                                </ListboxButton>

                                                <ListboxOptions
                                                    transition
                                                    className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in sm:text-sm"
                                                >
                                                    {types.map((type) => (
                                                        <ListboxOption
                                                            key={type}
                                                            value={type}
                                                            className="group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-indigo-600 data-[focus]:text-white"
                                                        >
                                                            <span className="block truncate font-normal group-data-[selected]:font-semibold">{type}</span>

                                                            <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-indigo-600 group-data-[focus]:text-white [.group:not([data-selected])_&]:hidden">
                                                        <CheckIcon aria-hidden="true" className="h-5 w-5" />
                                                    </span>
                                                        </ListboxOption>
                                                    ))}
                                                </ListboxOptions>
                                            </div>
                                        </Listbox>

                                        <Input
                                            name="text"
                                            value={question.text}
                                            placeholder="Question"
                                            onChange={(e) => handleQuestionChange(index, e)}
                                            className="col-span-2"
                                        />

                                        <Input
                                            name="description"
                                            value={question.description}
                                            placeholder="Description (optional)"
                                            onChange={(e) => handleQuestionChange(index, e)}
                                            className="col-span-2"
                                        />

                                        {question.type === 'checkbox' && (
                                            <div className="col-span-2">
                                                <Subheading>Options</Subheading>
                                                {question.options.map((option, optionIndex) => (
                                                    <Input
                                                        name="text"
                                                        value={option}
                                                        onChange={(e) => handleOptionChange(index, optionIndex, e)}
                                                        className="my-2"
                                                    />
                                                ))}

                                                <Button onClick={() => addOption(index)}>
                                                    <PlusIcon />
                                                    Add Option
                                                </Button>
                                            </div>
                                        )}
                                    </div>
                                </section>
                            </>
                        ))}

                        <Divider className="my-10" soft/>

                        <div className="flex justify-between">
                            <Button onClick={addQuestion}>Add another question</Button>

                            <button
                                type="submit"
                                className="rounded-md bg-violet-600 disabled:bg-stone-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-violet-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-violet-600"
                            >
                                Update Survey
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default UpdateSurvey;
