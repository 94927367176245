"use client";

import { cn } from "./utils.ts";
import React from "react";

export const Aurora = ({
                           className,
                           children,
                           showRadialGradient = true,
                           ...props
                       }) => {
    return (
        <main>
            <div
                className={cn(
                    "relative z-30 bg-black",
                    className
                )}
                {...props}
            >
                <div className="absolute inset-0 overflow-hidden">
                    <div
                        //   I'm sorry but this is what peak developer performance looks like // trigger warning
                        className={cn(
                            `
            [--white-gradient:repeating-linear-gradient(100deg,var(--light-purple)_0%,var(--light-purple)_7%,var(--transparent)_10%,var(--transparent)_12%,var(--light-purple)_16%)]
            [--aurora:repeating-linear-gradient(100deg,var(--azirevpn-purple)_10%,var(--azirevpn-purple)_15%,var(--azirevpn-purple)_20%,var(--violet-200)_25%,var(--azirevpn-purple)_30%)]
            [background-image:var(--white-gradient),var(--aurora)]
            [background-size:300%,_200%]
            [background-position:50%_50%,50%_50%]
            filter blur-[10px] invert
            after:content-[""] after:absolute after:inset-0 after:[background-image:var(--white-gradient),var(--aurora)] 
            after:[background-size:200%,_100%] 
            after:after:[background-attachment:fixed] after:mix-blend-difference
            pointer-events-none
            absolute -inset-[10px] opacity-50 will-change-transform`,

                            showRadialGradient &&
                            `[mask-image:radial-gradient(ellipse_at_100%_0%,black_10%,var(--transparent)_70%)]`
                        )}
                    ></div>
                </div>
                {children}
            </div>
        </main>
    );
};

