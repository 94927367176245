import React from 'react';
import ChannelMenu from "../components/LeftMenu";
import {Navigate, Outlet} from "react-router-dom";
import {useAuth} from "../utils/AuthProvider";
import {SurveyProvider} from "../storage/surveyContext";
import {FeedbackProvider} from "../storage/feedbackContext";

const ProtectedLayout = () => {
  const { isAuthenticated } = useAuth();

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  return (
    <div className="mx-auto h-full">
      <SurveyProvider>
        <FeedbackProvider>
          <div className="flex w-screen h-full">
            <div className="m-2 w-14">
              <div className="p-2">
                <p></p>
              </div>
            </div>
            <div className="m-2 rounded-xl overflow-hidden flex-1 flex border border-stone-200">
              <div className="w-[30rem]">
                <div
                    className="bg-stone-50 h-full rounded-tl-xl rounded-bl-xl overflow-hidden border-r border-stone-200 overflow-y-scroll">
                  <ChannelMenu/>
                </div>
              </div>
              <div className="flex-1 flex flex-col items-stretch h-full w-fit bg-white">
                <div className="h-[calc(100vh_-_2rem)] flex-1 rounded-br-xl overflow-hidden">
                  <Outlet/>
                </div>
              </div>
            </div>
          </div>
        </FeedbackProvider>
      </SurveyProvider>
    </div>
  )
};

export default React.memo(ProtectedLayout);
