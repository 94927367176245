import React from 'react';
import {Aurora} from "../../components/ui/Aurora";

function NotFound() {
    return (
        <div>
            <Aurora>
                <div className="relative isolate overflow-hidden py-8">
                    <div
                        className="absolute left-[calc(50%-4rem)] top-10 -z-10 transform-gpu blur-3xl sm:left-[calc(50%-18rem)] lg:left-48 lg:top-[calc(50%-30rem)] xl:left-[calc(50%-24rem)]"
                        aria-hidden="true"
                    >
                        <div
                            className="aspect-[1108/632] w-[69.25rem] bg-gradient-to-r from-[#b99eff] to-[#8c2fff] opacity-20"
                            style={{
                                clipPath:
                                    'polygon(73.6% 51.7%, 91.7% 11.8%, 100% 46.4%, 97.4% 82.2%, 92.5% 84.9%, 75.7% 64%, 55.3% 47.5%, 46.5% 49.4%, 45% 62.9%, 50.3% 87.2%, 21.3% 64.1%, 0.1% 100%, 5.4% 51.1%, 21.4% 63.9%, 58.9% 0.2%, 73.6% 51.7%)',
                            }}
                        />
                    </div>
                    <div className="m-auto max-w-xl px-8 lg:flex">
                        <div className="mx-auto max-w-2xl flex-shrink-0 lg:mx-0 lg:max-w-xl lg:pt-20">
                            <a href="https://www.azirevpn.com/">
                                <img
                                    className="h-8 w-auto"
                                    src="/img/azirevpn-logo-dark.svg"
                                    alt="AzireVPN"
                                />
                            </a>

                            <h1
                                className="m-auto mt-10 text-3xl lg:text-5xl font-bold text-medium-aubergine bg-gradient-to-r from-azirevpn-purple to-lavender inline-block text-transparent bg-clip-text">Privacy
                                first, <span
                                    className="underline text-4xl lg:text-7xl block">No exceptions</span></h1>
                            <p className="mt-3 text-md lg:text-lg leading-6 text-gray-300">
                                The VPN that prioritizes YOUR privacy. Stay anonymous, protect your data, and explore the web without limits. Your internet, your rules.
                            </p>
                        </div>
                    </div>
                </div>
            </Aurora>
        </div>
    );
}

export default NotFound;
