import {useState} from "react";
import {useNavigate} from "react-router-dom";
import {Field, FieldGroup, Label} from "../../components/catalyst/fieldset";
import {Input} from "../../components/catalyst/input";
import {Button} from "../../components/catalyst/button";
import config from "../../config/config";
import {useAuth} from "../../utils/AuthProvider";

export default function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const { login } = useAuth();
    const navigate = useNavigate();

    const handleLoginSuccess = (data) => {
        login(data.data.token);
        console.log('Login successful! Token saved to local storage.');

        navigate('/manager/surveys');
    };

    const submitLogin = async (e) => {
        e.preventDefault();

        try {
            const apiUrl = config.apiUrl
            const response = await fetch(apiUrl+'/api/v1/auth/login', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email, password })
            });

            if (!response.ok) {
                throw new Error('Login failed');
            }

            const data = await response.json();
            handleLoginSuccess(data);
        } catch (error) {
            console.error("Login error:", error);
        }
    };

    return (
        <div className="h-full mx-auto">
            <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                    <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                        <div>
                            <form>
                                <div className="bg-white rounded-t-2xl border border-stone-200 border-b-0 p-6">
                                    <div>
                                        <h2
                                            className="text-left text-2xl font-bold leading-9 tracking-tight text-stone-600 mb-4">
                                            Sign in to your account
                                        </h2>
                                    </div>

                                    <FieldGroup>
                                        <Field>
                                            <Label>Email</Label>
                                            <Input
                                                onChange={(e) => setEmail(e.target.value)}
                                                name="email"
                                            />
                                        </Field>
                                        <Field>
                                            <Label>Password</Label>
                                            <Input
                                                onChange={(e) => setPassword(e.target.value)}
                                                name="password"
                                                type="password"
                                            />
                                        </Field>
                                    </FieldGroup>
                                </div>
                                <div>
                                    <Button onClick={submitLogin} type="submit" className="w-full rounded-t-none">Sign
                                        in</Button>
                                </div>
                            </form>
                        </div>

                        <p className="mt-10 text-center text-sm text-stone-500">
                            Not a member?{' '}
                            <a href="#" className="font-semibold leading-6 text-violet-600 hover:text-violet-800">
                                Register here
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}
