import config from "../../config/config";

const FetchSurvey = async (surveyID) => {
    const apiUrl = config.apiUrl
    const response = await fetch(`${apiUrl}/api/v1/surveys/${surveyID}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
    });

    if (response.status === 401) {
        throw new Error('Unauthorized')
    }

    if (response.status === 404) {
        throw new Error('Bad endpoint')
    }

    if (!response.ok) {
        throw new Error(`Error: ${response.status}`)
    }

    return await response.json()
};

export default FetchSurvey;
