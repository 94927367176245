import config from "../../config/config";

const UpdateSurveyForm = async (surveyID, body) => {
    const token = localStorage.getItem('token');
    const apiUrl = config.apiUrl
    const response = await fetch(`${apiUrl}/api/v1/manager/surveys/${surveyID}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(body),
    });

    if (response.status === 401) {
        throw new Error('Unauthorized')
    }

    if (response.status === 404) {
        throw new Error('Bad endpoint')
    }

    if (!response.ok) {
        throw new Error(`${response.status}`)
    }

    return await response.json()
};

export default UpdateSurveyForm;
