import React, {useContext, useEffect, useState} from 'react';
import {NavLink, useLocation, useNavigate} from "react-router-dom";
import {SidebarBody} from "./catalyst/sidebar";
import {Fieldset, Legend} from "./catalyst/fieldset";
import {Text} from "./catalyst/text";
import {
  CommandLineIcon,
} from '@heroicons/react/24/outline'
import {useAuth} from "../utils/AuthProvider";
import surveyContext from "../storage/surveyContext";
import FetchSurveys from "./fetch/FetchSurveys";
import FetchSurveysFeedbacks from "./fetch/FetchSurveyFeedbacks";
import FeedbackContext from "../storage/feedbackContext";

const LeftMenu = () => {
  const { surveyState, surveyDispatch } = useContext(surveyContext)
  const { surveys, survey } = surveyState;
  const { feedbackState, feedbackDispatch } = useContext(FeedbackContext)
  const { feedback, feedbacks } = feedbackState;
  const { logout } = useAuth();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)
  const [navigation, setNavigation] = useState([
    { name: 'Surveys', href: '/manager/surveys', icon: CommandLineIcon, current: false },
  ])
  const navigate = useNavigate();

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  useEffect(() => {
    const fetchSurveys = async () => {
      try {
        (await FetchSurveys)().then((data) => {
          surveyDispatch({type: 'ADD', payload: data})
        })
      } catch (error) {
        console.error('Error fetching surveys:', error)
        if (error.message === 'Unauthorized') {
          logout()
        }
      }
    };

    const intervalId = setInterval(fetchSurveys, 5000)

    fetchSurveys()

    return () => {
      clearInterval(intervalId)
    };
  }, []);

  useEffect(() => {
    const fetchSurveysFeedback = async (surveyID) => {
      try {
        (await FetchSurveysFeedbacks)(surveyID).then((data) => {
          feedbackDispatch({type: 'ADD', payload: data})
        })
      } catch (error) {
        setError(error.message)
      } finally {
        setIsLoading(false)
      }
    };

    const fetchAllSurveyFeedbacks = () => {
      for (let survey of surveys) {
        if (survey.id === undefined) {
          continue
        }

        fetchSurveysFeedback(survey.id)
      }
    }

    fetchAllSurveyFeedbacks()

    const interval = setInterval(fetchAllSurveyFeedbacks, 10000)
    return () => clearInterval(interval)
  }, [surveys]);

  const navigateToSurvey = (survey) => {
    surveyDispatch({ type: 'SHOW', payload: survey })

    if (Object.keys(survey).length > 0) {
      navigate(`/manager/surveys/${survey.id}`, { replace: true })
    } else {
      navigate(`/manager/surveys`, { replace: true })
    }
  }

  useEffect(() => {
    setNavigation((prevNavigation) =>
        prevNavigation.map((item) => ({
          ...item,
          current: location.pathname.includes(item.href),
        }))
    );
  }, [location.pathname]);

  return (
    <div className="overflow-y-auto">
      <SidebarBody>
        <div className="pb-6">
          <nav aria-label="Sidebar" className="flex flex-1 flex-col">
            <ul role="list" className="-mx-2">
              {navigation.map((item) => (
                <li key={item.name}>
                  <NavLink
                    to={item.href}
                    replace={true}
                    className={classNames(
                      item.current ? 'bg-stone-50 text-violet-600' : 'text-stone-700 hover:bg-stone-50 hover:text-violet-600',
                      'group flex gap-x-3 rounded-md p-2 py-1 text-sm leading-6',
                    )}
                  >
                    <item.icon
                      aria-hidden="true"
                      className={classNames(
                        item.current ? 'text-violet-600' : 'text-stone-400 group-hover:text-violet-600',
                        'h-4 w-4 shrink-0 relative top-1',
                      )}
                    />
                    {item.name}
                  </NavLink>
                </li>
              ))}
            </ul>
          </nav>
        </div>

        { navigation.map((item) => {
          if (item.current) {
            switch (item.name) {
              case 'Surveys':
                return SurveyMenu(navigateToSurvey)
              default:
                return ''
            }
          }
        })}
      </SidebarBody>
    </div>
  )
};

const SurveyMenu = (navigateToSurvey) => {
  const { surveyState, surveyDispatch } = useContext(surveyContext)
  const { surveys } = surveyState;
  const { feedbackState, feedbackDispatch } = useContext(FeedbackContext)
  const { feedback, feedbacks } = feedbackState;

  const createNewSurvey = () => {
    feedbackDispatch({ type: 'SHOW', payload: {} })
    navigateToSurvey({})
  }

  const showFeedback = (item) => {
    feedbackDispatch({ type: 'SHOW', payload: item })
    navigateToSurvey({})
  }

  return (
      <>
        <div className="py-6 border-t border-stone-200">
          <Fieldset>
            <Legend className="flex justify-between">
              Surveys
              <button
                  type="button"
                  onClick={() => createNewSurvey()}
                  className="rounded bg-stone-700 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-stone-900"
              >
                Create
              </button>
            </Legend>
            <Text>Show from specific projects.</Text>
            <nav aria-label="Sidebar" className="flex flex-1 flex-col mt-3">
              {surveys.map(survey => (
                  <div key={survey.id}>
                    <h3
                        onClick={() => navigateToSurvey(survey)}
                        className="text-sm text-stone-500 mt-3 mb-1 cursor-pointer hover:text-stone-700"
                    >{survey.title}</h3>
                    <ul role="list" className="-mx-2 space-y-1">
                      {(feedbackState.feedbacks[survey.id] || []).map((item) => (
                          <li key={item.id}>
                            <a
                                onClick={() => showFeedback(item)}
                                className="text-stone-700 hover:bg-stone-100 cursor-pointer hover:text-violet-600 group flex gap-x-2 rounded-md p-2 pl-2 text-sm leading-6"
                            >
                              {item.id}
                            </a>
                          </li>
                      ))}
                    </ul>
                  </div>
              ))}
            </nav>
          </Fieldset>
        </div>
      </>
  )
}

export default React.memo(LeftMenu);
