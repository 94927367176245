import React from 'react';
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import Login from "./pages/auth/Login";
import PublicLayout from "./layouts/PublicLayout";
import {AuthProvider} from "./utils/AuthProvider";
import ProtectedLayout from "./layouts/ProtectedLayout";
import NotFound from "./pages/public/NotFound";
import Survey from "./pages/public/survey/Survey";
import CreateSurvey from "./pages/protected/survey/Survey";
import UpdateSurvey from "./pages/protected/survey/[id]/page";

function App() {
  return (
      <AuthProvider>
          <Router>
              <Routes>
                  <Route path="/" element={<PublicLayout><NotFound /></PublicLayout>} />
                  <Route path="/surveys/:surveyID" element={<PublicLayout><Survey /></PublicLayout>} />
                  <Route path="/auth/login" element={<PublicLayout><Login /></PublicLayout>} />
                  <Route element={<ProtectedLayout />}>
                      <Route path="/manager/surveys" element={<CreateSurvey />} />
                      <Route path="/manager/surveys/:surveyID" element={<UpdateSurvey />} />
                  </Route>
              </Routes>
          </Router>
      </AuthProvider>
  );
}

export default App;
